
$sl-content-height: 70vh;

.sl-app {
  height: 100vh;
}

.sl-h-100 {
  width: 100%;
}

.sl-w-100 {
  width: 100%;
}

.sl-channels-container {
  max-height: $sl-content-height;
  overflow-y: auto;
}

.sl-channels-content {
  height: 100%;
}

.sl-channels-channel {
  width: 100%;
}

.sl-messages-container {
  max-height: $sl-content-height;
  overflow-y: auto;
}

.sl-messages-content {
  height: 100%;
}

.sl-message-edit-container {
}

.sl-content {
  height: 90%;
}
